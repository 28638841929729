.nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  font-family: $headingFont;

  .left-side {
    display: flex;
  }
  .nav-link-wrapper {
    height: 22px;
    margin-right: 20px;
    font-size: 0.9rem;

    a {
      font-size: 1.5em;
      color: $dark;
      transition: 1s;
      &:hover {
        color: $gold;
      }
    }
  }
  .right-side {
    #logoHome {
      margin-top: -50px;
      height: 125px;
      width: 125px;
    }
  }
}

@media (max-width: 1025px) {
  .nav-wrapper {
    flex-direction: column;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: none;
    box-shadow: inset 0px -10px 79px -8px rgba(0, 0, 0, 0.58);
  }
  .right-side {
    #logoHome {
      order: 1;
      margin: 0;
      padding: 0;
    }
  }
  .left-side {
    order: 2;
    flex-direction: column;

    display: inline;
    text-align: center;
  }
}

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
