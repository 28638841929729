.videoContainer {
  height: 100vh;
  width: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  object-fit: cover;
}
.videoContainer video {
  min-width: 100%;
  position: fixed;
  top: 0;
  z-index: -999;
}
@media only screen and (max-width: 1025px) {
  .videoContainer {
    height: auto;
    width: 100vw;
    margin-top: -50px;
    margin-bottom: -60px;

    // visibility: hidden;
  }
  .videoContainer video {
    position: static;
  }
}
