/* skewed header */
// .bio-page {
// }

.skewed-header {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.skewed-header > .header-bg1 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/mtns.jpg");
  background-position: top;
}
.skewed-header > .header-bg2 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/wind.jpg");
  background-position: center;
}
.skewed-header > .header-bg3 {
  position: absolute;
  top: 00px;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/tallMTN.jpg");
  background-position: center;
}

.skewed-header > .skewed-header-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.skewed-header > .skewed-header-wrapper > .skewed-header-content {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skewed-header
  > .skewed-header-wrapper
  > .skewed-header-content
  > .heading-wrapper {
  font-family: $headingFont;
  background-color: $black;
  color: $gold;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 175px;
  padding: 10px;
  text-align: center;
}

@media only screen and (max-width: 1025px) {
  .skewed-header > .header-bg2 {
    background-image: none;
  }
  .skewed-header > .header-bg3 {
    background-image: none;
  }
  .skewed-header > .header-bg1 {
    background-image: none;
  }
}
