.films-link-wrapper {
  display: flex;
  justify-content: center;

  .vid-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 150px;
    padding-left: 200px;
    padding-right: 200px;

    a {
      text-decoration: none;
    }

    .vidLink {
      // position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 240px;
      width: 525px;
    }

    .link-header,
    .link-description {
      display: flex;
      justify-content: center;
      justify-items: center;
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
      transition: 1s ease-in-out;
      color: transparent;
    }

    .link-header:hover,
    .link-description:hover {
      color: white;
      font-weight: 400;
    }
  }
}
@media (max-width: 1025px) {
  .vid-links {
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0;
  }
  .gridList {
    width: 100vw;
    height: auto;
  }
}
