.squares-wrapper {
  display: flex;
  justify-content: center;
  margin-top: -200px;
  margin-bottom: 100px;
}

.squares-wrapper > .squares {
  width: 1000px;
}

.squares-wrapper > .squares > .square {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -4px;
}

.square > .img-wrapper img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border: 1px solid $gold;
}

.square > .square-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 42px;
  font-family: $bodyFont;
}

.square > .square-text-wrapper h1 {
  color: $gold;
  font-family: $headingFont;
}
