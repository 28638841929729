body {
  margin: 0px;
  font-family: 'Titillium Web', sans-serif;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
}

.videoContainer {
  height: 100vh;
  width: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  object-fit: cover;
}

.videoContainer video {
  min-width: 100%;
  position: fixed;
  top: 0;
  z-index: -999;
}

@media only screen and (max-width: 1025px) {
  .videoContainer {
    height: auto;
    width: 100vw;
    margin-top: -50px;
    margin-bottom: -60px;
  }
  .videoContainer video {
    position: static;
  }
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  font-family: "Vollkorn", serif;
}

.nav-wrapper .left-side {
  display: flex;
}

.nav-wrapper .nav-link-wrapper {
  height: 22px;
  margin-right: 20px;
  font-size: 0.9rem;
}

.nav-wrapper .nav-link-wrapper a {
  font-size: 1.5em;
  color: #424242;
  transition: 1s;
}

.nav-wrapper .nav-link-wrapper a:hover {
  color: #DAA520;
}

.nav-wrapper .right-side #logoHome {
  margin-top: -50px;
  height: 125px;
  width: 125px;
}

@media (max-width: 1025px) {
  .nav-wrapper {
    flex-direction: column;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: none;
    box-shadow: inset 0px -10px 79px -8px rgba(0, 0, 0, 0.58);
  }
  .right-side #logoHome {
    order: 1;
    margin: 0;
    padding: 0;
  }
  .left-side {
    order: 2;
    flex-direction: column;
    display: inline;
    text-align: center;
  }
}

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177, 0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177, 0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177, 0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177, 0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177, 0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177, 0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: -300px;
  padding-bottom: 300px;
}

.MuiGridListTile-root-2 {
  display: flex;
  justify-content: center;
}

.gridList {
  overflow: scroll;
  width: 780px;
  height: 1060px;
  border-bottom: black;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);
  margin-bottom: 50px;
}

.icon :hover {
  color: #424242;
}

.two-column {
  display: grid;
  grid-gap: 21px;
  grid-template-columns: 1fr 1fr;
}

.missionStatement {
  height: 50vh;
  width: 100vw;
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 30px 300px;
  font-family: "Vollkorn", serif;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);
}

.missionStatement body {
  text-align: center;
}

.missionStatement body:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: 100%;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.header {
  display: inline-block;
  color: #DAA520;
  font-family: "Vollkorn", serif;
  font-size: 12em;
}

.statement {
  display: flex;
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  color: #424242;
}

.contact-me {
  text-decoration: none;
  border: none;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 2vw;
  font-weight: 900;
  text-decoration: none;
  color: white;
  display: inline-block;
  background-size: 120% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -ms-background-clip: text;
  -ms-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-image: linear-gradient(45deg, #DAA520, #424242, #f19cbb, #1b1b1b);
  animation: 0.8s shake infinite alternate;
}

.contact-me:hover {
  color: black;
}

@media only screen and (max-width: 1025px) {
  .missionStatement {
    flex-flow: row wrap;
    padding: 0;
    height: 100vh;
    width: 100vw;
    height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
  }
  .statement {
    text-shadow: 3px 3px 15px #1b1b1b;
    font-size: 4vw;
  }
  .contact-me {
    font-size: 5vw;
  }
}

@keyframes shad-anim {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 100% -100%;
  }
}

@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

/* Footer styles */
.footer {
  background: repeating-linear-gradient(45deg, #2b2b2b 0%, #2b2b2b 10%, #222222 0%, #222222 50%) 0/15px 15px;
  color: #cbcbcb;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: absolute;
  height: 400px;
  margin-top: -4px;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);
}

.footer > .logo-footer img {
  width: 200px;
  height: 200px;
  filter: brightness(150%);
  margin-bottom: -10px;
}

.social-media-icons-wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 300px;
  display: flex;
  justify-content: space-around;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.social-media-icons-wrapper a {
  color: #424242;
  text-decoration: none;
  font-size: 3em;
}

.social-media-icons-wrapper a:hover {
  color: #424242;
  -webkit-animation: neon2 1.5s ease-in-out infinite alternate;
  -moz-animation: neon2 1.5s ease-in-out infinite alternate;
  animation: neon2 1.5s ease-in-out infinite alternate;
}

.footer > .copyright-wrapper {
  margin-top: 1px;
  margin-bottom: 42px;
  font-size: 0.7em;
  color: #858585;
}

@media (max-width: 1025px) {
  .footer {
    height: auto;
    margin: 0;
    width: 100%;
  }
  .footer .logo-footer img {
    margin-bottom: 0;
  }
  .footer .social-media-icons-wrapper {
    flex-direction: column;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .footer .copyright-wrapper {
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}

@-webkit-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #f19cbb, 0 0 70px #f19cbb, 0 0 80px #f19cbb, 0 0 100px #f19cbb, 0 0 150px #f19cbb;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #f19cbb, 0 0 35px #f19cbb, 0 0 40px #f19cbb, 0 0 50px #f19cbb, 0 0 75px #f19cbb;
  }
}

@-moz-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #f19cbb, 0 0 70px #f19cbb, 0 0 80px #f19cbb, 0 0 100px #f19cbb, 0 0 150px #f19cbb;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #f19cbb, 0 0 35px #f19cbb, 0 0 40px #f19cbb, 0 0 50px #f19cbb, 0 0 75px #f19cbb;
  }
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #f19cbb, 0 0 70px #f19cbb, 0 0 80px #f19cbb, 0 0 100px #f19cbb, 0 0 150px #f19cbb;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #f19cbb, 0 0 35px #f19cbb, 0 0 40px #f19cbb, 0 0 50px #f19cbb, 0 0 75px #f19cbb;
  }
}

.films-link-wrapper {
  display: flex;
  justify-content: center;
}

.films-link-wrapper .vid-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;
  padding-left: 200px;
  padding-right: 200px;
}

.films-link-wrapper .vid-links a {
  text-decoration: none;
}

.films-link-wrapper .vid-links .vidLink {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 240px;
  width: 525px;
}

.films-link-wrapper .vid-links .link-header,
.films-link-wrapper .vid-links .link-description {
  display: flex;
  justify-content: center;
  justify-items: center;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
  transition: 1s ease-in-out;
  color: transparent;
}

.films-link-wrapper .vid-links .link-header:hover,
.films-link-wrapper .vid-links .link-description:hover {
  color: white;
  font-weight: 400;
}

@media (max-width: 1025px) {
  .vid-links {
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0;
  }
  .gridList {
    width: 100vw;
    height: auto;
  }
}

.contact-grid-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 100px;
  margin-top: -400px;
}

.logo {
  display: none;
}

.contact-me-grid-wrapper {
  font-size: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #424242;
}

.Form {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="phone"],
input[type="email"] {
  margin: 8px 0;
  border-radius: 4px;
  -moz-box-shadow: 0px 3px 5px 4px #424242;
  -webkit-box-shadow: 0px 3px 5px 4px #424242;
  box-shadow: 0px 3px 5px 4px #424242;
}

button {
  font-family: "Font Awesome 5 Free";
  background-color: #424242;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid #1b1b1b;
}

button:hover {
  background-color: #1b1b1b;
}

@media only screen and (min-width: 1025px) {
  .contact-grid-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: space-around;
    line-height: 100px;
    margin-top: -400px;
  }
  .logo {
    display: inline;
  }
  .contact-me-grid-wrapper {
    color: #fcfcfc;
  }
  input {
    width: 30vw;
    height: auto;
  }
  button {
    width: 30vw;
    height: auto;
  }
}

/* skewed header */
.skewed-header {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.skewed-header > .header-bg1 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/mtns.jpg");
  background-position: top;
}

.skewed-header > .header-bg2 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/wind.jpg");
  background-position: center;
}

.skewed-header > .header-bg3 {
  position: absolute;
  top: 00px;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: skewY(-6deg);
  transform-origin: top left;
  filter: brightness(50%);
  background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/tallMTN.jpg");
  background-position: center;
}

.skewed-header > .skewed-header-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.skewed-header > .skewed-header-wrapper > .skewed-header-content {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skewed-header
> .skewed-header-wrapper
> .skewed-header-content
> .heading-wrapper {
  font-family: "Vollkorn", serif;
  background-color: #1b1b1b;
  color: #DAA520;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 175px;
  padding: 10px;
  text-align: center;
}

@media only screen and (max-width: 1025px) {
  .skewed-header > .header-bg2 {
    background-image: none;
  }
  .skewed-header > .header-bg3 {
    background-image: none;
  }
  .skewed-header > .header-bg1 {
    background-image: none;
  }
}

.squares-wrapper {
  display: flex;
  justify-content: center;
  margin-top: -200px;
  margin-bottom: 100px;
}

.squares-wrapper > .squares {
  width: 1000px;
}

.squares-wrapper > .squares > .square {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -4px;
}

.square > .img-wrapper img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border: 1px solid #DAA520;
}

.square > .square-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 42px;
  font-family: "Open Sans", sans-serif;
}

.square > .square-text-wrapper h1 {
  color: #DAA520;
  font-family: "Vollkorn", serif;
}
