.contact-grid-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 100px;
  margin-top: -400px;
}
.logo {
  display: none;
}
.contact-me-grid-wrapper {
  font-size: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #424242;
}
.Form {
  display: flex;
  flex-direction: column;
}
input[type="text"],
input[type="phone"],
input[type="email"] {
  margin: 8px 0;
  border-radius: 4px;
  -moz-box-shadow: 0px 3px 5px 4px $dark;
  -webkit-box-shadow: 0px 3px 5px 4px $dark;
  box-shadow: 0px 3px 5px 4px $dark;
}
button {
  font-family: "Font Awesome 5 Free";
  background-color: $dark;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border: 2px solid $black;
}
button:hover {
  background-color: $black;
}

@media only screen and (min-width: 1025px) {
  .contact-grid-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: space-around;
    line-height: 100px;
    margin-top: -400px;
  }
  .logo {
    display: inline;
  }
  .contact-me-grid-wrapper {
    color: $white;
  }
  input {
    width: 30vw;
    height: auto;
  }
  button {
    width: 30vw;
    height: auto;
  }
}
