.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: -300px;
  padding-bottom: 300px;
}
.MuiGridListTile-root-2 {
  display: flex;
  justify-content: center;
}

.gridList {
  overflow: scroll;
  width: 780px;
  height: 1060px;
  border-bottom: black;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);
  margin-bottom: 50px;
}
.icon :hover {
  color: $dark;
}
