/* Footer styles */
.footer {
  background: repeating-linear-gradient(
      45deg,
      #2b2b2b 0%,
      #2b2b2b 10%,
      #222222 0%,
      #222222 50%
    )
    0 / 15px 15px;
  color: #cbcbcb;
  font-family: $bodyFont;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: absolute;
  height: 400px;
  margin-top: -4px;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);
}

.footer > .logo-footer img {
  width: 200px;
  height: 200px;
  filter: brightness(150%);
  margin-bottom: -10px;
}

.social-media-icons-wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 300px;
  display: flex;
  justify-content: space-around;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;

  a {
    color: $dark;
    text-decoration: none;
    font-size: 3em;
    &:hover {
      color: $dark;
      -webkit-animation: neon2 1.5s ease-in-out infinite alternate;
      -moz-animation: neon2 1.5s ease-in-out infinite alternate;
      animation: neon2 1.5s ease-in-out infinite alternate;
    }
  }
}

.footer > .copyright-wrapper {
  margin-top: 1px;
  margin-bottom: 42px;
  font-size: 0.7em;
  color: #858585;
}
@media (max-width: 1025px) {
  .footer {
    height: auto;
    margin: 0;
    width: 100%;
    .logo-footer img {
      margin-bottom: 0;
    }
    .social-media-icons-wrapper {
      flex-direction: column;
      height: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    .copyright-wrapper {
      width: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
  }
}

@-webkit-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px $pink,
      0 0 70px $pink, 0 0 80px $pink, 0 0 100px $pink, 0 0 150px $pink;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $pink,
      0 0 35px $pink, 0 0 40px $pink, 0 0 50px $pink, 0 0 75px $pink;
  }
}

@-moz-keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px $pink,
      0 0 70px $pink, 0 0 80px $pink, 0 0 100px $pink, 0 0 150px $pink;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $pink,
      0 0 35px $pink, 0 0 40px $pink, 0 0 50px $pink, 0 0 75px $pink;
  }
}
@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px $pink,
      0 0 70px $pink, 0 0 80px $pink, 0 0 100px $pink, 0 0 150px $pink;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px $pink,
      0 0 35px $pink, 0 0 40px $pink, 0 0 50px $pink, 0 0 75px $pink;
  }
}
