// @import url(https://fonts.googleapis.com/css?family=Righteous);

.missionStatement {
  height: 50vh;
  width: 100vw;
  background-color: $white;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 30px 300px;
  font-family: $headingFont;
  box-shadow: inset 0px -26px 79px -8px rgba(0, 0, 0, 0.58);

  body {
    text-align: center;
  }
  body:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    height: 100%;
  }
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
.header {
  display: inline-block;
  color: $gold;
  font-family: $headingFont;
  font-size: 12em;
}

.statement {
  display: flex;
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-size: 1em;
  font-family: $bodyFont;
  font-weight: 900;
  color: $dark;
}

.contact-me {
  text-decoration: none;
  border: none;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 2vw;
  font-weight: 900;
  text-decoration: none;
  color: white;
  display: inline-block;
  background-size: 120% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -ms-background-clip: text;
  -ms-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-image: linear-gradient(45deg, $gold, $dark, $pink, $black);
  animation: 0.8s shake infinite alternate;

  &:hover {
    color: black;
  }
}
@media only screen and (max-width: 1025px) {
  .missionStatement {
    flex-flow: row wrap;
    padding: 0;
    height: 100vh;
    width: 100vw;
    //background-image: url("https://kelseycreationsimages.s3-us-west-2.amazonaws.com/light.jpg");
    height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
  }
  .statement {
    // color: $white;
    text-shadow: 3px 3px 15px $black;
    font-size: 4vw;
  }
  .contact-me {
    font-size: 5vw;
  }
}

@keyframes shad-anim {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 100% -100%;
  }
}

@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
